import React, { useEffect, useContext, useState, useRef } from "react";
import FixedImage from "../fixedImage";
import ThemeContext from '../../context/ThemeContext';
import LocationCard from "../locationCard";
import styles from "./styles.module.scss";

const MyLocation = ({locations, arrowRedLocal, locationIcon, rightArrowYellowOffsite}) => {
	const myLocRef = useRef();
	const { location, getLocation } = useContext(ThemeContext);
	const [ nearestLoc, setNearestLoc ] = useState(null);

	useEffect( () => {
		function sortByDistance(){

			let locationList = [];
	
			locations.forEach((wpLocation, i) => {   
				const distance = getDistanceFromLatLonInMi(wpLocation.birdeyeLocation.location.lat, wpLocation.birdeyeLocation.location.lng, location.lat, location.lng);
				wpLocation.distance = distance;
				locationList.push(wpLocation);
			});
	
			locationList.sort(function(a, b){
				var keyA = a.distance,
					keyB = b.distance;
				// Compare the 2 dates
				if(keyA < keyB) return -1;
				if(keyA > keyB) return 1;
				return 0;
			});
	
			// apply sorted list to state
			setNearestLoc(locationList[0]);
	
			function getDistanceFromLatLonInMi(lat1,lon1,lat2,lon2) {
				var R = 6371; // Radius of the earth in km
				var dLat = deg2rad(lat2-lat1);  // deg2rad below
				var dLon = deg2rad(lon2-lon1); 
				var a = 
					Math.sin(dLat/2) * Math.sin(dLat/2) +
					Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
					Math.sin(dLon/2) * Math.sin(dLon/2)
					; 
				var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
				var d = R * c; // Distance in km
				var miles = d * 0.621371;
				return miles;
			}
	
			function deg2rad(deg) {
				return deg * (Math.PI/180)
			}
		}

		if( location ){
			sortByDistance();
		}
	}, [location, locations])

	useEffect( () => {
		const inViewCheck = setInterval(() => { 
			if( typeof window !== undefined && typeof document !== undefined && myLocRef && myLocRef.current ){
				const rect = myLocRef.current.getBoundingClientRect();
  				const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  				const isInView = !(rect.bottom < 0 || rect.top - viewHeight >= 0);

				if( isInView ){
					getLocation();
					clearInterval(inViewCheck);
				}
			}
		}, 500);

		return () => {
			clearInterval(inViewCheck)
		}
	}, [getLocation])

  	return (
		<div className={styles.MyLocation} ref={myLocRef}>
			<span className={styles.heading}><FixedImage image={locationIcon} passedClass={styles.icon} /> My Location</span>
			{ nearestLoc === null && (
				<div className={styles.top}>
					<span className={styles.title}>Loading...</span>
				</div>
			)}
			{ nearestLoc !== null && <LocationCard location={nearestLoc} arrowRedLocal={arrowRedLocal} rightArrowYellowOffsite={rightArrowYellowOffsite} /> }
		</div>
  	)
}

export default MyLocation;
